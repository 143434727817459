import React, { useState, useEffect } from 'react';
import logo from './assets/Frame 82.svg';
import './App.css';
import { useNavigate } from 'react-router-dom';
import Enrollment4form from './enrollment4';

import img1 from './assets/webgst.svg';
import mobile from './assets/mgst.svg'; // Import the mobile image
import video from './assets/video.png';
import books from './assets/Frame 96 (1).svg';
import gif from './assets/fast-forward.gif';
import alarm from './assets/alarm.svg';
import arrow from './assets/Asset 1 (1).svg';
import clients from './assets/Frame 91.svg';

import includes from './assets/includes/1.svg';
import includes2 from './assets/includes/10.svg';
import includes3 from './assets/includes/2.svg';
import includes4 from './assets/includes/3.svg';
import includes14 from './assets/includes/4.svg';
import includes5 from './assets/includes/5.svg';
import includes6 from './assets/includes/6.svg';
import includes7 from './assets/includes/7.svg';
import includes8 from './assets/includes/8.svg';
import includes9 from './assets/includes/9.svg';
import profile from './assets/review/Ellipse 5.svg';
import profile1 from './assets/review/Ellipse 4.svg';
import star from './assets/5str.png';
import students from './assets/Frame 66.svg';
import sqr from './assets/Rectangle 13.svg';
import sqr1 from './assets/Rectangle 13 (1).svg';
import sqr2 from './assets/Rectangle 13 (2).svg';
import sq3r from './assets/Rectangle 13 (3).svg';
import sqr4 from './assets/Rectangle 13 (4).svg';
import people from './assets/people.png';
import tara from './assets/tara.png';

import cross from './assets/cancel_24dp_FILL0_wght400_GRAD0_opsz24 1.svg';
import hstar from './assets/hstar.svg';
const chapters = [
  { title: 'Chapter 1: Introduction to GST', content: '' },
  { title: 'Chapter 2: E-Way Bill', content: '' },
  { title: 'Chapter 3: GST e-Invoicing', content: '' },
  { title: 'Chapter 4: GST in Tally Prime', content: '' },
  { title: 'Chapter 5: Hierarchy of Calculating Tax in Transactions', content: '' },
];


const moreChapters = [
  { title: 'Chapter 6: Recording GST compliant transactions', content: '' },
  { title: 'Chapter 7: E-Way Bill Report in Tally', content: '' },
  { title: 'Chapter 8: Input Tax Credit Set Off against Liability', content: '' },
  { title: 'Chapter 9: GST Tax Payment', content: '' },
  { title: 'Chapter 10: Generating GST Returns for Regular Dealer in Tally', content: '' },
  { title: 'Chapter 11: Filing GST Returns in Tally', content: '' },
  { title: 'Chapter 12: Generating e-Invoice in TallyPrime', content: '' },
  { title: 'Chapter 13: Quarterly Return Monthly Payment Scheme', content: '' },
  { title: 'Chapter 14: Composition Dealer under GST Regime', content: '' },
  { title: 'Chapter 15: Recording GST compliant transactions', content: '' },
  { title: 'Chapter 16: Accounting Intrastate Supply of Goods & Services', content: '' },
  { title: 'Chapter 17: Purchases from Unregistered Dealer', content: '' },
  { title: 'Chapter 18: Purchases from Composition Dealer', content: '' },
  { title: 'Chapter 19: Accounting Mixed Supply under GST', content: '' },
  { title: 'Chapter 20: Accounting Composite Supply under GST', content: '' },
  { title: 'Chapter 21: Supply of Exempted/Nil-rated Goods under GST', content: '' },
  { title: 'Chapter 22: Supply of Goods to Special Economic Zone (SEZ)', content: '' },
  { title: 'Chapter 23: Supply of Goods to Other Territory', content: '' },
  { title: 'Chapter 24: Reverse Charge Mechanism under GST', content: '' },
  { title: 'Chapter 25: Export of Goods under GST', content: '' },
  { title: 'Chapter 26: Advance Receipts under GST', content: '' },
  { title: 'Chapter 27: Generating E-Invoice Report', content: '' },
  { title: 'Chapter 28: Generating E-Way Bill Report', content: '' },
  { title: 'Chapter 29: Input Tax Credit Set Off against Liability', content: '' },
  { title: 'Chapter 30: GST Tax Payment', content: '' },
  { title: 'Chapter 31: Reversal of Input Tax Credit', content: '' },
  { title: 'Chapter 32: Generating GST Returns in Tally', content: '' },
  { title: 'Chapter 33: E-Filing GST Returns in Tally', content: '' },
];





const handleRemove = () => {
  document.getElementById('headerBar').style.display = 'none';
};

function GST() {
  const [activeLevel, setActiveLevel] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [imageSrc, setImageSrc] = useState(img1);
  const [showMore, setShowMore] = useState(false); // State to manage the image source // State to manage the image source
  const navigate = useNavigate();

  const handleRowClick = () => {
    navigate('/');
  };
  const handleRowClick1 = () => {
    navigate('/tprime');
  };
  const handleRowClick2 = () => {
    navigate('/financial');
  };
  const handleRowClick3 = () => {
    navigate('/tprofessional');
  };
  const handleEnrollClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setImageSrc(mobile); // Change the image source to mobile
      } else {
        setImageSrc(img1); // Reset the image source to img1
      }
    };

    window.addEventListener('resize', handleResize);

    // Check the screen size initially
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    const handleScroll = () => {
      const card = document.querySelector('.card');
      if (window.scrollY > 0) {
        if (window.innerWidth <= 768) { // Assuming mobile screens are 768px or less
          card.style.marginTop = '0%';
        } else {
          card.style.marginTop = '-42%';
        }
      } else {
        if (window.innerWidth <= 768) { // Assuming mobile screens are 768px or less
          card.style.marginTop = '0%';
        } else {
          card.style.marginTop = '-27%';
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  
  const initialCountdownTime = 3 * 60 * 60 + 55 * 60 + 30; // initial countdown time in seconds
  const [countdownTime, setCountdownTime] = useState(initialCountdownTime);

  useEffect(() => {
      if (countdownTime > 0) {
          const timerId = setTimeout(() => {
              setCountdownTime(countdownTime - 1);
          }, 1000);

          return () => clearTimeout(timerId); // Cleanup the timeout on component unmount
      }
  }, [countdownTime]);

  const formatTime = (time) => {
      const hours = Math.floor(time / 3600);
      const minutes = Math.floor((time % 3600) / 60);
      const seconds = time % 60;

      return `${hours}h ${minutes}m ${seconds}s`;
  };


  return (
    <>
      <section className='contain'>
        <div className='tcard'>

          <div className="header-bar" id="headerBar">
            <img src={cross} alt="cross" className="cross-img" onClick={handleRemove} />
            <div className="header-content">
              <p>"Last Chance! Limited Time Offer on Our Popular Course!"</p>
              <p style={{ fontWeight: 'bold', textAlign: 'center' }}>1 day left</p>
            </div>
          </div>

          <navbar className="navbar padding">
                    <img src={logo} alt="Logo" className="navbar-logo" />
                    <div className="image-container" onClick={handleEnrollClick} style={{ cursor: 'pointer', marginLeft: '20px', position: 'relative' }}>
                                <img  src={books} alt="Books" className='books' />
                                <img src={gif} alt="Overlay" className="overlay-gif" />
                    </div>

     
                </navbar>
          <div className='wide'>
            <img id="responsive-img" src={imageSrc} alt="img1" />
          </div>
          <div className="card">
          <div className="card-video">
          <iframe
  className='youtube'
  src="https://www.youtube.com/embed/ja69wYeqzTI?si=rqms-Nwe2tddeRRl"
  title="YouTube video player"
  frameBorder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
  referrerPolicy="strict-origin-when-cross-origin"
  allowFullScreen
></iframe>
    </div>
            <div className="card-content">
            <span> <h3 style={{ marginBottom: '0px', color: 'red',fontWeight:'570' }}>50% OFF</h3> </span>
                        <p style={{ marginTop: '0px', paddingTop: '1px' }}>Total duration: 90 hrs</p>
                        <div className="limited-time-offer">
                            <span className="icon"><img src={alarm} alt="alarm" /></span>
                            <span className='offer' style={{color:'red'}}> <strong>Limited time offer :<span id="countdown">{formatTime(countdownTime)}</span></strong></span>

                        </div>
              <button className="enroll-button" onClick={handleEnrollClick}><span>Enroll now </span></button>
              <div className="card-includes">
                <h4 style={{ marginBottom: '1px' }}>This course includes</h4>

                <div className="course-item">
                  <span className="icon"><img src={includes} alt="icon1" /></span>
                  30+ hours on-demand video
                </div>
                <div className="course-item">
                  <span className="icon"><img src={includes14} alt="icon2" /></span>
                  Live sessions available
                </div>
                <div className="course-item">
                  <span className="icon"><img src={includes3} alt="icon3" /></span>
                  Tally job portal access
                </div>
                <div className="course-item">
                  <span className="icon"><img src={includes2} alt="icon4" /></span>
                  Assignment for practices
                </div>
              </div>

            </div>
          </div>
        </div>
        <Enrollment4form show={showModal} handleClose={handleCloseModal} />
        <section className='second-section'>


        </section>
      </section>
      <section className='padding'>
        <div className='learn'>
          <h2>What you will learn</h2>
          <div className="learn-item">
            <span className="arrow"><img src={arrow} alt="arrow" /></span>
            Understand the meaning and types of inventories.
          </div>
          <div className="learn-item">
            <span className="arrow"><img src={arrow} alt="arrow" /></span>
            Creation of inventory masters such as Stock Groups, Stock Category, Warehouse and
            Batches.
          </div>
          <div className="learn-item">
            <span className="arrow"><img src={arrow} alt="arrow" /></span>
            the fundamentals and concepts of Outstanding Management.
          </div>
          <div className="learn-item">
            <span className="arrow"><img src={arrow} alt="arrow" /></span>
            Management of Sales and Purchase Cycle.

          </div>
          <div className="learn-item">
            <span className="arrow"><img src={arrow} alt="arrow" /></span>
            Introduction of GST, GST in TallyPrime
          </div>
          <div className="learn-item">
            <span className="arrow"><img src={arrow} alt="arrow" /></span>
            How to maintain additional costs incurred on purchase of stock.
          </div>
          <div className="learn-item">
            <span className="arrow"><img src={arrow} alt="arrow" /></span>
            Recording GST-compliant transactions, E-Way Bill Report in Tally.
          </div>
          <div className="learn-item">
            <span className="arrow"><img src={arrow} alt="arrow" /></span>
            Securing Financial Information of the Company, Creating Security Control, User Roles, Creation of Users and Change User.
          </div>
          <div className="learn-item">
            <span className="arrow"><img src={arrow} alt="arrow" /></span>
            creating cost centre classes in Tally and updating the reports instantly.
          </div>
          <div className="learn-item">
            <span className="arrow"><img src={arrow} alt="arrow" /></span>
            how to maintain Budgets & Scenario Management in Tally.
          </div>
          <div className="learn-item">
            <span className="arrow"><img src={arrow} alt="arrow" /></span>
            how to generate and print all Books and Registers in Tally including Inventory Reports.

          </div>
        </div>
      </section>
      <section className='padding mpad'>
        <h2 className="heading">GST using tally prime</h2>
        <div className="duration">Total duration: 20 hrs</div>
        <div className="chapterst">
          {chapters.map((chapter, index) => (
            <div key={index} className="chaptert">
              <div className="chapterts-title" style={{fontWeight:'600'}}>{chapter.title}</div>
              <div className="chaptert-content">{chapter.content}</div>
            </div>
          ))}
          {showMore && moreChapters.map((chapter, index) => (
            <div key={index} className="chaptert">
              <div className="chapterts-title" style={{fontWeight:'600'}}>{chapter.title}</div>
              <div className="chaptert-content">{chapter.content}</div>
            </div>
          ))}
        </div>
        <div className='butvol'>
          <button className="show-more-button" onClick={() => setShowMore(!showMore)}>
            {showMore ? 'Show Less' : 'Show More'}
          </button>
        </div>
      </section>
      <div className='padding mpad '>
                <h2 className='hcl'>Clients</h2>
                <img className='clients' src={clients} alt="clients" />
            </div>
      <section className='padding'>
        <div className="course-includes">
          <h2>This course includes</h2>
          <div className="course-items">
            <div className="course-item">
              <span className="icon"><img src={includes} alt="icon1" /></span>
              90+ hours on-demand video
            </div>
            <div className="course-item">
              <span className="icon"><img src={includes14} alt="icon2" /></span>
              Live sessions available
            </div>
            <div className="course-item">
              <span className="icon"><img src={includes3} alt="icon3" /></span>
              Tally job portal access
            </div>
            <div className="course-item">
              <span className="icon"><img src={includes2} alt="icon4" /></span>
              Assignment for practices
            </div>
            <div className="course-item">
              <span className="icon"><img src={includes4} alt="icon5" /></span>
              One to one classes
            </div>
            <div className="course-item">
              <span className="icon"><img src={includes5} alt="icon6" /></span>
              Access on mobile and television
            </div>
            <div className="course-item">
              <span className="icon"><img src={includes6} alt="icon7" /></span>
              Full lifetime access
            </div>
            <div className="course-item">
              <span className="icon"><img src={includes7} alt="icon8" /></span>
              Certification of completion from Tally
            </div>
            <div className="course-item">
              <span className="icon"><img src={includes8} alt="icon9" /></span>
              Weekly test to test you
            </div>
            <div className="course-item">
              <span className="icon"><img src={includes9} alt="icon10" /></span>
              Time flexibility
            </div>
          </div>
        </div>
      </section>
      {/* <div className='padding'>
        <img style={{ width: '65%', marginTop: '5%' }} src={exercises} alt="clients" />
      </div> */}
      <section className='padding mpad'>
        <div className="featured-reviews">
          <h2>Featured reviews</h2>
          <div className="reviews">
            <div className="review">
              <div className="profile">
                <img src={profile} alt="Profile" className="profile-img" />
                <div>
                  <p className="name">Diksha</p>
                  <p className="stars">
                <img src={tara} alt="Star" className="star-img" />
                <img src={tara} alt="Star" className="star-img" />
                <img src={tara} alt="Star" className="star-img" />
                <img src={tara} alt="Star" className="star-img" />
                <img src={tara} alt="Star" className="star-img" /></p>
       
                </div>
              </div>
          
              <p className="review-text">
              The GST using tally prime comprehensive course at Dovetail  Solutions was exceptional. The instructors were knowledgeable and provided practical insights into Tally. After completing the course, I was able to secure a job as a Tally Trainer at Dovetail Solutions. This course is perfect for anyone looking to build a strong foundation in Tally
             . </p>
            </div>
            <div className="review">
              <div className="profile">
                <img src={profile1} alt="Profile" className="profile-img" />
                <div>
                  <p className="name">Maheswar Sharma</p>
                  <p className="stars">
                <img src={tara} alt="Star" className="star-img" />
                <img src={tara} alt="Star" className="star-img" />
                <img src={tara} alt="Star" className="star-img" />
                <img src={tara} alt="Star" className="star-img" />
                <img src={tara} alt="Star" className="star-img" /></p>
                  
                </div>
              </div>
            
              <p className="review-text">
              I completed the Tally course from Dovetail Solutions, and it exceeded my expectations. The hands-on training and detailed curriculum were invaluable. I am now working as an Accountant and Admin at Tapn Industries. I highly recommend this course for its practical approach and excellent placement support.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section class="padding mpad">
        <div className='projects'>
          <h2>Students also bought</h2>

          <div class="item-row" onClick={handleRowClick} style={{ cursor: 'pointer' }}>
            <img src={sqr} alt="profile" class="profile-img" />
            <div class="item-content">
            <div class="item-info">
              <h4>Tally Essentials</h4>
              <p> <span style={{ color: '#0056b3' }}>Total hours 106 • </span> Updated on 6/27/2024</p>
            </div>
            <div class="item-stats">
              <div class="rating">
              <img src={tara} alt="star" class="star-img" />
                <img src={tara} alt="star" class="star-img" />
                <img src={tara} alt="star" class="star-img" />
                <img src={tara} alt="star" class="star-img" />
                <img src={hstar} alt="star" class="star-img" />
                <span>4.8</span>
              </div>
          <div class="people">
                <img src={people} alt="people" class="people-img" />
                <span>50,492</span>
              </div> 
              <div class="price">

                <span className='rpay'></span>
              </div>
            </div>
            </div>
          </div>

          {/* <div class="item-row" onClick={handleRowClick1} style={{ cursor: 'pointer' }}>
            <img src={sqr1} alt="profile" class="profile-img" />
            <div class="item-content">
            <div class="item-info">
              <h4>Tally Prime GST e-Filing Course</h4>
              <p> <span style={{ color: '#0056b3' }}>Total hours 20 • </span> Updated on 6/27/2024</p>
            </div>
            <div class="item-stats">
              <div class="rating">
              <img src={tara} alt="star" class="star-img" />
                <img src={tara} alt="star" class="star-img" />
                <img src={tara} alt="star" class="star-img" />
                <img src={tara} alt="star" class="star-img" />
                <img src={hstar} alt="star" class="star-img" />
                <span>4.9</span>
              </div>
               <div class="people">
                <img src={people} alt="people" class="people-img" />
                <span>48,567</span>
              </div> 
              <div class="price">

                <span className='rpay'></span>
              </div>
            </div>
            </div>
          </div> */}

          {/* <div class="item-row" onClick={handleRowClick2} style={{ cursor: 'pointer' }}>
            <img src={sqr2} alt="profile" class="profile-img" />
            <div class="item-content">
            <div class="item-info">
              <h4>Financial Accounting Using Tally Prime</h4>
              <p> <span style={{ color: '#0056b3' }}>Total hours 40 • </span> Updated on 6/27/2024</p>
            </div>
            <div class="item-stats">
              <div class="rating">
              <img src={tara} alt="star" class="star-img" />
                <img src={tara} alt="star" class="star-img" />
                <img src={tara} alt="star" class="star-img" />
                <img src={tara} alt="star" class="star-img" />
                <img src={hstar} alt="star" class="star-img" />
                <span>4.7</span>
              </div>
               <div class="people">
                <img src={people} alt="people" class="people-img" />
                <span>48,492</span>
              </div> 
              <div class="price">

                <span className='rpay'></span>
              </div>
            </div>
            </div>
          </div> */}


          <div class="item-row" onClick={handleRowClick3} style={{ cursor: 'pointer' }}>
            <img src={sqr4} alt="profile" class="profile-img" />
            <div class="item-content">
            <div class="item-info">
              <h4>Tally Professional</h4>
              <p> <span style={{ color: '#0056b3' }}>Total hours 20 • </span> Updated on 6/27/2024</p>
            </div>
            <div class="item-stats">
              <div class="rating">
                <img src={tara} alt="star" class="star-img" />
                <img src={tara} alt="star" class="star-img" />
                <img src={tara} alt="star" class="star-img" />
                <img src={tara} alt="star" class="star-img" />
                <img src={hstar} alt="star" class="star-img" />
                <span>4.7</span>
              </div>
               <div class="people">
                <img src={people} alt="people" class="people-img" />
                <span>48,392</span>
              </div> 
              <div class="price">

                <span className='rpay'></span>
              </div>
            </div>
            </div>
          </div>


        </div>
      </section>
      <section className='footer'>
        <div style={{ textAlign: 'center', color: 'white', paddingTop: '1%', paddingBottom: '1%', fontSize: '10px' }}>
          All Rights Reserved   |   Powered by Dovetail Solutions
        </div>
      </section>
    </>
  );
}

export default GST;
