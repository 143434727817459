import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';

const Enrollment2form = ({ show, handleClose }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        city: '',
        course: '', // Add course to the formData state
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        let bodyFormData = new FormData();
        bodyFormData.append('name', formData.name);
        bodyFormData.append('email', formData.email);
        bodyFormData.append('mobile', formData.mobile);
        bodyFormData.append('city', formData.city);
        bodyFormData.append('course', formData.course);
        console.log(formData);
        e.preventDefault();
        try {
            let res = await axios.post('https://dovetaileducation.in/sendmail.php', bodyFormData, {"Content-Type": "multipart/form-data"});
        } catch (error) {
            console.error('here was an error sending the email qqq', error);
        }
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Tally Prime</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formMobile">
                        <Form.Label>Mobile</Form.Label>
                        <Form.Control
                            type="text"
                            name="mobile"
                            value={formData.mobile}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formCity">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                            type="text"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formCourse">
                        <Form.Label>Course</Form.Label>
                        <Form.Control
                            as="select"
                            name="course"
                            value={formData.course}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select a course</option>
                            <option value="Tally Essential">Tally Essential</option>
                            <option value="Tally Professional">Tally Professional</option>
                            <option value="Tally Prime GST e filling course">Tally Prime GST e filling course</option>
                            <option value="Financial accounting using tally prime">Financial accounting using tally prime</option>
                            <option value="GST using tally prime">GST using tally prime</option>
                        </Form.Control>
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default Enrollment2form;
