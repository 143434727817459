import React, { useState, useEffect } from 'react';
import logo from './assets/Frame 82.svg';
import './App.css';
import { useNavigate } from 'react-router-dom';
import Enrollment3form from './enrollment3';

import img1 from './assets/webfinan.svg';
import mobile from './assets/mfinan.svg'; // Import the mobile image
import video from './assets/video.png';
import books from './assets/Frame 96 (1).svg';
import gif from './assets/fast-forward.gif';
import alarm from './assets/alarm.svg';
import arrow from './assets/Asset 1 (1).svg';
import clients from './assets/Frame 90.svg';

import includes from './assets/includes/1.svg';
import includes2 from './assets/includes/10.svg';
import includes3 from './assets/includes/2.svg';
import includes4 from './assets/includes/3.svg';
import includes14 from './assets/includes/4.svg';
import includes5 from './assets/includes/5.svg';
import includes6 from './assets/includes/6.svg';
import includes7 from './assets/includes/7.svg';
import includes8 from './assets/includes/8.svg';
import includes9 from './assets/includes/9.svg';
import profile from './assets/review/Ellipse 1.svg';
import profile1 from './assets/review/Ellipse 4.svg';
import star from './assets/5str.png';
import students from './assets/Frame 66.svg';
import sqr from './assets/Rectangle 13.svg';
import sqr1 from './assets/Rectangle 13 (1).svg';
import sqr2 from './assets/Rectangle 13 (2).svg';
import sq3r from './assets/Rectangle 13 (3).svg';
import sqr4 from './assets/Rectangle 13 (4).svg';
import people from './assets/people.png';
import tara from './assets/tara.png';
import hstar from './assets/hstar.svg';
import cross from './assets/cancel_24dp_FILL0_wght400_GRAD0_opsz24 1.svg';


const chapters = [
  { title: 'Chapter 1: Introduction to Tally Prime', content: 'Overview of Tally Prime features and interface - Importance of Tally Prime in GST compliance - Brief introduction to GST (Goods and Services Tax) in India' },
  { title: 'Chapter 2: Setting Up Tally Prime for GST', content: 'Installing Tally Prime software - Configuring company settings for GST compliance - Setting up GST rates and tax classifications' },
  { title: 'Chapter 3: Recording GST Transactions in Tally Prime', content: 'Creating GST-compliant invoices - Recording sales and purchases - Input tax credit (ITC) utilization in Tally Prime' },
  { title: 'Chapter 4: Generating GST Reports', content: 'Overview of GST reports available in Tally Prime - Generating GSTR-1 (Outward Supplies) report - Generating GSTR-3B (Summary Return) report' }
];

const moreChapters = [
  { title: 'Chapter 5: E-Filing of GST Returns Using Tally Prime', content: 'Understanding the process of e-filing GST returns - Step-by-step guide to filing GSTR-1 and GSTR-3B using Tally Prime - Troubleshooting common e-filing issues' },
  { title: 'Chapter 6: Advanced GST Transactions in Tally Prime', content: 'Handling exports and imports under GST - Composite and mixed supplies in Tally Prime - Reverse charge mechanism (RCM) transactions' },
  { title: 'Chapter 7: GST Audit and Reconciliation', content: 'Conducting GST reconciliation in Tally Prime - Auditing GST transactions and records - Resolving mismatches and discrepancies' },
  { title: 'Chapter 8: GST Compliance and Updates', content: 'Staying compliant with latest GST rules and updates - Using Tally Prime for compliance with e-invoicing requirements - Future trends and developments in GST and Tally Prime' },
  { title: 'Chapter 9: Practical Applications and Case Studies', content: 'Practical examples of complex GST transactions in Tally Prime - Case studies demonstrating real-world scenarios - Q&A sessions and problem-solving discussions' }
];


const handleRemove = () => {
  document.getElementById('headerBar').style.display = 'none';
};

function Financial() {
  const [activeLevel, setActiveLevel] = useState(0);
  const [activeChapter, setActiveChapter] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [imageSrc, setImageSrc] = useState(img1);
  const [showMore, setShowMore] = useState(false);// State to manage the image source
  const navigate = useNavigate();

  const handleChapterClick = (index) => {
    setActiveChapter(index === activeChapter ? null : index);
  };

  const handleRowClick = () => {
    navigate('/');
  };
  const handleRowClick1 = () => {
    navigate('/gst');
  };
  const handleRowClick2 = () => {
    navigate('/tprime');
  };
  const handleRowClick3 = () => {
    navigate('/tprofessional');
  };

  const handleEnrollClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setImageSrc(mobile); // Change the image source to mobile
      } else {
        setImageSrc(img1); // Reset the image source to img1
      }
    };

    window.addEventListener('resize', handleResize);

    // Check the screen size initially
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  useEffect(() => {
    const handleScroll = () => {
      const card = document.querySelector('.card');
      if (window.scrollY > 0) {
        if (window.innerWidth <= 768) { // Assuming mobile screens are 768px or less
          card.style.marginTop = '0%';
        } else {
          card.style.marginTop = '-47%';
        }
      } else {
        if (window.innerWidth <= 768) { // Assuming mobile screens are 768px or less
          card.style.marginTop = '0%';
        } else {
          card.style.marginTop = '-33%';
        }
      }
    };


    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  
  const initialCountdownTime = 3 * 60 * 60 + 55 * 60 + 30; // initial countdown time in seconds
  const [countdownTime, setCountdownTime] = useState(initialCountdownTime);

  useEffect(() => {
      if (countdownTime > 0) {
          const timerId = setTimeout(() => {
              setCountdownTime(countdownTime - 1);
          }, 1000);

          return () => clearTimeout(timerId); // Cleanup the timeout on component unmount
      }
  }, [countdownTime]);

  const formatTime = (time) => {
      const hours = Math.floor(time / 3600);
      const minutes = Math.floor((time % 3600) / 60);
      const seconds = time % 60;

      return `${hours}h ${minutes}m ${seconds}s`;
  };


  return (
    <>
      <div className='tcard'>
        <div className="header-bar" id="headerBar">
          <img src={cross} alt="cross" className="cross-img" onClick={handleRemove} />
          <div className="header-content">
            <p>"Last Chance! Limited Time Offer on Our Popular Course!"</p>
            <p style={{ fontWeight: 'bold', textAlign: 'center' }}>1 day left</p>
          </div>
        </div>

        <navbar className="navbar padding">
                    <img src={logo} alt="Logo" className="navbar-logo" />
                    <div className="image-container" onClick={handleEnrollClick} style={{ cursor: 'pointer', marginLeft: '20px', position: 'relative' }}>
                                <img  src={books} alt="Books" className='books' />
                                <img src={gif} alt="Overlay" className="overlay-gif" />
                    </div>

     
                </navbar>
        <div className='wide'>
          <img id="responsive-img" src={imageSrc} alt="img1" />
        </div>


        <div className="card">
        <div className="card-video">
      <iframe
        className='youtube'
        src="https://www.youtube.com/embed/IQPnxaZiF2U?si=kDI9G2XmmwM_gahM"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    </div>
          <div className="card-content">
          <span> <h3 style={{ marginBottom: '0px', color: 'red',fontWeight:'570' }}>50% OFF</h3> </span>
                        <p style={{ marginTop: '0px', paddingTop: '1px' }}>Total duration: 40 hrs</p>
                        <div className="limited-time-offer">
                            <span className="icon"><img src={alarm} alt="alarm" /></span>
                            <span className='offer' style={{color:'red'}}> <strong>Limited time offer :<span id="countdown">{formatTime(countdownTime)}</span></strong></span>

                        </div>
            <button className="enroll-button" onClick={handleEnrollClick}><span> Enroll now </span></button>
            <div className="card-includes">
              <h4 style={{ marginBottom: '1px' }}>This course includes</h4>

              <div className="course-item">
                <span className="icon"><img src={includes} alt="icon1" /></span>
                30+ hours on-demand video
              </div>
              <div className="course-item">
                <span className="icon"><img src={includes14} alt="icon2" /></span>
                Live sessions available
              </div>
              <div className="course-item">
                <span className="icon"><img src={includes3} alt="icon3" /></span>
                Tally job portal access
              </div>
              <div className="course-item">
                <span className="icon"><img src={includes2} alt="icon4" /></span>
                Assignment for practices
              </div>
            </div>

          </div>
        </div>
      </div>
      <Enrollment3form show={showModal} handleClose={handleCloseModal} />
      <section className='second-section'>

      </section>
      <section className='padding'>
  <div className='learn'>
    <h2>What you will learn</h2>
    <div className="learn-item">
      <span className="arrow"><img src={arrow} alt="arrow" /></span>
      Fundamentals of Accounting
    </div>
    <div className="learn-item">
      <span className="arrow"><img src={arrow} alt="arrow" /></span>
      Introduction to Tally Prime
    </div>
    <div className="learn-item">
      <span className="arrow"><img src={arrow} alt="arrow" /></span>
      Maintaining Chart of Accounts
    </div>
    <div className="learn-item">
      <span className="arrow"><img src={arrow} alt="arrow" /></span>
      Recording and Maintaining Accounting Transactions
    </div>
    <div className="learn-item">
      <span className="arrow"><img src={arrow} alt="arrow" /></span>
      Goods and Services Tax
    </div>
    <div className="learn-item">
      <span className="arrow"><img src={arrow} alt="arrow" /></span>
      Data Security
    </div>
    <div className="learn-item">
      <span className="arrow"><img src={arrow} alt="arrow" /></span>
      Accounts Receivable and Payable Management
    </div>
    <div className="learn-item">
      <span className="arrow"><img src={arrow} alt="arrow" /></span>
      Purchase and Sales Order Management
    </div>
    <div className="learn-item">
      <span className="arrow"><img src={arrow} alt="arrow" /></span>
      Generating and Printing Reports
    </div>
  </div>
</section>

      
      <section className='padding mpad'>
      <h2 className="heading"> Financal Accounting using tally prime</h2>
      <div className="chapterst" style={{ marginTop: '3%' }}>
          {chapters.map((chapter, index) => (
            <div key={index} className="chaptert">
              <div className="chaptert-title" onClick={() => handleChapterClick(index)}>
                {chapter.title}
              </div>
              {activeChapter === index && (
                <div className="chaptert-content">{chapter.content}</div>
              )}
            </div>
          ))}
          {showMore && moreChapters.map((chapter, index) => (
            <div key={index + chapters.length} className="chaptert">
              <div className="chaptert-title" onClick={() => handleChapterClick(index + chapters.length)}>
                {chapter.title}
              </div>
              {activeChapter === index + chapters.length && (
                <div className="chaptert-content">{chapter.content}</div>
              )}
            </div>
          ))}
        </div>
        <div className='butvol'>
          <button className="show-more-button" onClick={() => setShowMore(!showMore)}>
            {showMore ? 'Show Less' : 'Show More'}
          </button>
        </div>
      </section>

<div className='padding mpad '>
                <h2 className='hcl'>Clients</h2>
                <img className='clients' src={clients} alt="clients" />
            </div>
            <section className='padding'>
  <div className="course-includes">
    <h2>This course includes</h2>
    <div className="course-items">
      <div className="course-item">
        <span className="icon"><img src={includes} alt="icon1" /></span>
        40+ hours on-demand video
      </div>
      <div className="course-item">
        <span className="icon"><img src={includes5} alt="icon6" /></span>
        Access on mobile and TV
      </div>
      <div className="course-item">
        <span className="icon"><img src={includes6} alt="icon7" /></span>
        Full lifetime access
      </div>
      <div className="course-item">
        <span className="icon"><img src={includes7} alt="icon8" /></span>
        Certificate of completion from Tally
      </div>
      <div className="course-item">
        <span className="icon"><img src={includes14} alt="icon10" /></span>
        Internet National Certificate Value
      </div>
      <div className="course-item">
        <span className="icon"><img src={includes3} alt="icon3" /></span>
        Tally Job Portal Access
      </div>
      <div className="course-item">
        <span className="icon"><img src={includes2} alt="icon4" /></span>
        Assignment for Practices
      </div>
      <div className="course-item">
        <span className="icon"><img src={includes8} alt="icon9" /></span>
        Weekly Test to test you
      </div>
      <div className="course-item">
        <span className="icon"><img src={includes4} alt="icon5" /></span>
        One to one classes
      </div>
      <div className="course-item">
        <span className="icon"><img src={includes9} alt="icon10" /></span>
        Batch According to You
      </div>
    </div>
  </div>
</section>

      {/* <div className='padding'>
        <img style={{ width: '65%', marginTop: '5%' }} src={exercises} alt="clients" />
      </div> */}
      <section className='padding mpad'>
        <div className="featured-reviews">
          <h2>Featured reviews</h2>
          <div className="reviews">
            <div className="review">
              <div className="profile">
                <img src={profile} alt="Profile" className="profile-img" />
                <div>
                  <p className="name">Rajat Sharma</p>
                  <p  className="stars">
                <img src={tara} alt="Star" className="star-img" />
                <img src={tara} alt="Star" className="star-img" />
                <img src={tara} alt="Star" className="star-img" />
                <img src={tara} alt="Star" className="star-img" />
                <img src={hstar} alt="Star" className="star-img" /></p>
                 
                </div>
              </div>
             
              <p className="review-text">
              The Financal accounting using tally prime course at Dovetail Solutions was instrumental in my career development. The comprehensive training enabled me to start my own Tally institute in Dharampur, Himachal Pradesh. The course content and teaching methodology were exceptional, and I highly recommend it to anyone looking to master Tally.
              </p>
            </div>
            <div className="review">
              <div className="profile">
                <img src={profile1} alt="Profile" className="profile-img" />
                <div>
                  <p className="name">Maheshwar</p>
                  <p className="stars">
                <img src={tara} alt="Star" className="star-img" />
                <img src={tara} alt="Star" className="star-img" />
                <img src={tara} alt="Star" className="star-img" />
                <img src={tara} alt="Star" className="star-img" />
                <img src={hstar} alt="Star" className="star-img" /></p>

                </div>
              </div>
              
              <p className="review-text">
              I completed the Tally course from Dovetail Solutions, and it exceeded my expectations. The hands-on training and detailed curriculum were invaluable. I am now working as an Accountant and Admin at Tapn Industries. I highly recommend this course for its practical approach and excellent placement support.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section class="padding mpad">
        <div className='projects'>
          <h2>Students also bought</h2>

          <div class="item-row" onClick={handleRowClick} style={{ cursor: 'pointer' }}>
            <img src={sqr} alt="profile" class="profile-img" />
            <div class="item-content">
            <div class="item-info">
              <h4>Tally Essentials</h4>
              <p> <span style={{ color: '#0056b3' }}>Total hours 106 • </span> Updated on 6/27/2024</p>
            </div>
            <div class="item-stats">
              <div class="rating">
                <img src={tara} alt="star" class="star-img" />
                <img src={tara} alt="star" class="star-img" />
                <img src={tara} alt="star" class="star-img" />
                <img src={tara} alt="star" class="star-img" />
                <img src={hstar} alt="star" class="star-img" />
                <span>4,8</span>
              </div>
               <div class="people">
                <img src={people} alt="people" class="people-img" />
                <span>50,492</span>
              </div> 
              <div class="price">

                <span className='rpay'></span>
              </div>
            </div>
            </div>
          </div>

          <div class="item-row" onClick={handleRowClick1} style={{ cursor: 'pointer' }}>
            <img src={sq3r} alt="profile" class="profile-img" />
            <div class="item-content">
            <div class="item-info">
              <h4>GST Using Tally Prime</h4>
              <p> <span style={{ color: '#0056b3' }}>Total hours 90 • </span> Updated on 6/27/2024</p>
            </div>
            <div class="item-stats">
              <div class="rating">
                <img src={tara} alt="star" class="star-img" />
                <img src={tara} alt="star" class="star-img" />
                <img src={tara} alt="star" class="star-img" />
                <img src={tara} alt="star" class="star-img" />
                <img src={hstar} alt="star" class="star-img" />
                <span>4.8</span>
              </div>
               <div class="people">
                <img src={people} alt="people" class="people-img" />
                <span>48,974</span>
              </div> 
              <div class="price">

                <span className='rpay'></span>
              </div>
            </div>
            </div>
          </div>

          <div class="item-row" onClick={handleRowClick2} style={{ cursor: 'pointer' }}>
            <img src={sqr2} alt="profile" class="profile-img" />
            <div class="item-content">
            <div class="item-info">
              <h4>Tally Prime GST e-Filing Course</h4>
              <p> <span style={{ color: '#0056b3' }}>Total hours 20 • </span> Updated on 6/27/2024</p>
            </div>
            <div class="item-stats">
              <div class="rating">
                <img src={tara} alt="star" class="star-img" />
                <img src={tara} alt="star" class="star-img" />
                <img src={tara} alt="star" class="star-img" />
                <img src={tara} alt="star" class="star-img" />
                <img src={hstar} alt="star" class="star-img" />
                <span>4.9</span>
              </div>
               <div class="people">
                <img src={people} alt="people" class="people-img" />
                <span>48,567</span>
              </div> 
              <div class="price">

                <span className='rpay'></span>
              </div>
            </div>
            </div>
          </div>


          <div class="item-row" onClick={handleRowClick3} style={{ cursor: 'pointer' }}>
            <img src={sqr1} alt="profile" class="profile-img" />
            <div class="item-content">
            <div class="item-info">
              <h4>Tally Professional</h4>
              <p> <span style={{ color: '#0056b3' }}>Total hours 20 • </span> Updated on 6/27/2024</p>
            </div>
            <div class="item-stats">
              <div class="rating">
                <img src={tara} alt="star" class="star-img" />
                <img src={tara} alt="star" class="star-img" />
                <img src={tara} alt="star" class="star-img" />
                <img src={tara} alt="star" class="star-img" />
                <img src={hstar} alt="star" class="star-img" />
                <span>4.7</span>
              </div>
               <div class="people">
                <img src={people} alt="people" class="people-img" />
                <span>48,392</span>
              </div> 
              <div class="price">

                <span className='rpay'></span>
              </div>
            </div>
            </div>
          </div>


        </div>
      </section>
      <section className='footer'>
        <div style={{ textAlign: 'center', color: 'white', paddingTop: '1%', paddingBottom: '1%', fontSize: '10px' }}>
          All Rights Reserved   |   Powered by Dovetail Solutions
        </div>
      </section>
    </>
  );
}

export default Financial;
