import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'typeface-poppins';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/free-mode';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';




import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import TProfessional from './tprofessional';
import TPrime from './tprime';
import Financial from './financial';
import GST from './gst';
import Home from './home'; // Assuming Home component contains your current code

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tprofessional" element={<TProfessional />} />
        <Route path="/tprime" element={<TPrime />} />
        <Route path="/financial" element={<Financial />} />
        <Route path="/gst" element={<GST />} />
        
        
      </Routes>
    </Router>
  );
}

export default App;
