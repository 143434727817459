import React, { useState, useEffect } from 'react';


import { useNavigate } from 'react-router-dom';
import Enrollmentform from './enrollment';
import logo from './assets/Frame 82.svg';
import './App.css';
import img1 from './assets/essential.svg';
import mobile from './assets/moble tallye.svg'; // Import the mobile image
import video from './assets/video.png';
import books from './assets/Frame 96 (1).svg';
import gif from './assets/fast-forward.gif';
import alarm from './assets/alarm.svg';
import arrow from './assets/Asset 1 (1).svg';
import clients from './assets/Frame 90 (2).svg';
import includes from './assets/includes/1.svg';
import includes2 from './assets/includes/10.svg';
import includes3 from './assets/includes/2.svg';
import includes4 from './assets/includes/3.svg';
import includes14 from './assets/includes/4.svg';
import includes5 from './assets/includes/5.svg';
import includes6 from './assets/includes/6.svg';
import includes7 from './assets/includes/7.svg';
import includes8 from './assets/includes/8.svg';
import includes9 from './assets/includes/9.svg';
import profile from './assets/review/Ellipse 9.svg';
import profile1 from './assets/review/Ellipse 8.svg';
import students from './assets/Frame 66.svg';
import sqr from './assets/Rectangle 13.svg';
import sqr1 from './assets/Rectangle 13 (1).svg';
import sqr2 from './assets/Rectangle 13 (2).svg';
import sq3r from './assets/Rectangle 13 (3).svg';
import sqr4 from './assets/Rectangle 13 (4).svg';

import people from './assets/people.png';
import tara from './assets/tara.png';
import hstar from './assets/hstar.svg';
import rupee from './assets/rupay.png';
import cross from './assets/cancel_24dp_FILL0_wght400_GRAD0_opsz24 1.svg';
// import client1 from './assets/logos jatin (1)/1.jpg';
// import client2 from './assets/logos jatin (1)/2.jpg';
// import client3 from './assets/logos jatin (1)/3.jpg';
// import client4 from './assets/logos jatin (1)/4.jpg';
// import client5 from './assets/logos jatin (1)/5.jpg';
// import client6 from './assets/logos jatin (1)/6.jpg';
// import client7 from './assets/logos jatin (1)/7.jpg';
// import client8 from './assets/logos jatin (1)/8.jpg';

const levels = [
    {
        title: 'Level 1',
        content: [
            'Chapter 1: Fundamentals of Accounting',
            'Chapter 2: Introduction to Tally Prime',
            'Chapter 3: Maintaining Chart of Accounts',
            'Chapter 4: Recording and Maintaining Accounting Transactions',
            'Chapter 5: Goods and Services Tax',
            'Chapter 6: Banking',
            'Chapter 7: Generating Financial Statements and MIS Reports',
            'Chapter 8: Data Security',
            'Chapter 9: Company Data Management',
        ],
    },

    {
        title: 'Level 2',
        content: [
            'Chapter 1: Storage and Classification of Inventory  ',
            'Chapter 2: Accounts Receivable and Payable Management',
            'Chapter 3: Purchase and Sales Order Management',
            'Chapter 4: Tracking Additional Costs of Purchase',
            'Chapter 5: Goods and Services Tax',
            'Chapter 6: Cost/Profit Centers Management',
            'Chapter 7: Budgets and Scenarios',
            'Chapter 8: Generating and Printing Reports'
        ],
    },

    {
        title: 'Level 3',
        content: [
            'Chapter 1: Goods and Services Tax',
            'Chapter 2: Tax Deducted at Source',
            'Chapter 3: Management of Business Data',
            'Chapter 4: Moving to the Next Financial Year'
        ],
    },
];

const handleRemove = () => {
    document.getElementById('headerBar').style.display = 'none';
};

function Home() {
    const [activeLevel, setActiveLevel] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [imageSrc, setImageSrc] = useState(img1); // State to manage the image source
    const navigate = useNavigate();

    const handleRowClick = () => {
        navigate('/tprofessional');
    };
    const handleRowClick1 = () => {
        navigate('/tprime');
    };
    const handleRowClick2 = () => {
        navigate('/financial');
    };
    const handleRowClick3 = () => {
        navigate('/gst');
    };

    const handleEnrollClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };



    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setImageSrc(mobile); // Change the image source to mobile
            } else {
                setImageSrc(img1); // Reset the image source to img1
            }
        };

        window.addEventListener('resize', handleResize);

        // Check the screen size initially
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const card = document.querySelector('.card');
            if (window.scrollY > 0) {
                if (window.innerWidth <= 768) { // Assuming mobile screens are 768px or less
                    card.style.marginTop = '0%';
                } else {
                    card.style.marginTop = '-42%';
                }
            } else {
                if (window.innerWidth <= 768) { // Assuming mobile screens are 768px or less
                    card.style.marginTop = '0%';
                } else {
                    card.style.marginTop = '-24%';
                }
            }
        };


        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const initialCountdownTime = 3 * 60 * 60 + 55 * 60 + 30; // initial countdown time in seconds
    const [countdownTime, setCountdownTime] = useState(initialCountdownTime);

    useEffect(() => {
        if (countdownTime > 0) {
            const timerId = setTimeout(() => {
                setCountdownTime(countdownTime - 1);
            }, 1000);

            return () => clearTimeout(timerId); // Cleanup the timeout on component unmount
        }
    }, [countdownTime]);

    const formatTime = (time) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;

        return `${hours}h ${minutes}m ${seconds}s`;
    };

    return (
        <>
            <div className='tcard'>
                <div className="header-bar" id="headerBar">
                    <img src={cross} alt="cross" className="cross-img" onClick={handleRemove} />
                    <div className="header-content">
                        <p>"Last Chance! Limited Time Offer on Our Popular Course!"</p>
                        <p style={{ fontWeight: 'bold', textAlign: 'center' }}>1 day left</p>
                    </div>
                </div>

                <navbar className="navbar padding">
                    <img src={logo} alt="Logo" className="navbar-logo" />
                    <div className="image-container" onClick={handleEnrollClick} style={{ cursor: 'pointer', marginLeft: '20px', position: 'relative' }}>
                        <img src={books} alt="Books" className='books' />
                        <img src={gif} alt="Overlay" className="overlay-gif" />
                    </div>


                </navbar>
                <div className='wide'>
                    <img className='mimg' id="responsive-img" src={imageSrc} alt="img1" />
                </div>

                <div className="card">
                    <div className="card-video">
                    <iframe
  className='youtube'
  src="https://www.youtube.com/embed/az9TwvwkVNc?si=h-TE-VSHvqua6v4z"
  title="YouTube video player"
  frameBorder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
  referrerPolicy="strict-origin-when-cross-origin"
  allowFullScreen
></iframe>
                    </div>
                    <div className="card-content">
                        <span> <h3 style={{ marginBottom: '0px', color: 'red', fontWeight: '570' }}>50% OFF</h3> </span>
                        <p style={{ marginTop: '0px', paddingTop: '1px' }}>Total duration: 106 hrs</p>
                        <div className="limited-time-offer">
                            <span className="icon"><img src={alarm} alt="alarm" /></span>
                            <span className='offer' style={{ color: 'red' }}> <strong>Limited time offer :<span id="countdown">{formatTime(countdownTime)}</span></strong></span>

                        </div>
                        <button className="enroll-button" onClick={handleEnrollClick}><span>Enroll now</span></button>
                        <div className="card-includes">
                            <h4 style={{ marginBottom: '2px' }}>This course includes</h4>
                            <div className="course-item">
                                <span className="icon"><img src={includes} alt="icon1" /></span>
                                30+ hours on-demand video
                            </div>
                            <div className="course-item">
                                <span className="icon"><img src={includes14} alt="icon2" /></span>
                                Live sessions available
                            </div>
                            <div className="course-item">
                                <span className="icon"><img src={includes3} alt="icon3" /></span>
                                Tally job portal access
                            </div>
                            <div className="course-item">
                                <span className="icon"><img src={includes2} alt="icon4" /></span>
                                Assignment for practices
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Enrollmentform show={showModal} handleClose={handleCloseModal} />
            {/* <section className='second-section'>

            </section> */}
            <section className='padding'>
                <div className='learn'>
                    <h2>What you will learn</h2>
                    <div className="learn-item">
                        <span className="arrow"><img src={arrow} alt="arrow" /></span>
                        Fundamentals of Accountancy, starting with Tally Prime.
                    </div>
                    <div className="learn-item">
                        <span className="arrow"><img src={arrow} alt="arrow" /></span>
                        Accounting using Tally explains how simple it is to download and install Tally in the system.
                    </div>
                    <div className="learn-item">
                        <span className="arrow"><img src={arrow} alt="arrow" /></span>
                        Ledgers, Groups, Stock Items and Units of Measure Creation.
                    </div>
                    <div className="learn-item">
                        <span className="arrow"><img src={arrow} alt="arrow" /></span>
                        How to record transactions such as Receipt, Contra, Payment, Purchase, Sales, Debit Note, Credit Note, and Journal Vouchers with practical scenarios.
                    </div>
                    <div className="learn-item">
                        <span className="arrow"><img src={arrow} alt="arrow" /></span>
                        Introduction of GST, GST in TallyPrime
                    </div>
                    <div className="learn-item">
                        <span className="arrow"><img src={arrow} alt="arrow" /></span>
                        Covers the basic Banking Feature, Cheque Printing, Bank Reconciliation, and Generating Deposit Slips including Payment Advice.
                    </div>
                    <div className="learn-item">
                        <span className="arrow"><img src={arrow} alt="arrow" /></span>
                        All MIS reports in Tally; Trail Balance, Balance Sheet, Profit and Loss account, Cash Flow Statement, Ratio Analysis, Daybook, Receipts and Payments, Purchase and Sales Register.
                    </div>
                    <div className="learn-item">
                        <span className="arrow"><img src={arrow} alt="arrow" /></span>
                        Securing Financial Information of the Company, Creating Security Control, User Roles, Creation of Users and Change User.
                    </div>
                    <div className="learn-item">
                        <span className="arrow"><img src={arrow} alt="arrow" /></span>
                        How to securely take company data and restore without any data loss.
                    </div>
                </div>
            </section>
            <section className='padding mpad'>

                <h2 className="title">Tally Essentials</h2>
                <div className="duration">Total duration: 106 hrs</div>
                <div className="container">
                    <div className="level-tabs-container">
                        <div className="level-tabs">
                            <div className="bottom-line"></div>

                            <div
                                className={`level-tab ${activeLevel === 0 ? 'active' : ''}`}
                                onClick={() => setActiveLevel(0)}
                            >
                                Level 1
                            </div>
                            <div
                                className={`level-tab ${activeLevel === 1 ? 'active' : ''}`}
                                onClick={() => setActiveLevel(1)}
                            >
                                Level 2
                            </div>
                            <div
                                className={`level-tab ${activeLevel === 2 ? 'active' : ''}`}
                                onClick={() => setActiveLevel(2)}
                            >
                                Level 3
                            </div>
                        </div>


                    </div>


                    <div className="content">
                        <div className="level-title">{levels[activeLevel].title}</div>
                        <div className="chapter-section">
                            <div className="left-chapters">
                                {levels[activeLevel].content.slice(0, Math.ceil(levels[activeLevel].content.length / 2)).map((chapter, index) => (
                                    <div className="chapter" key={index}>{chapter}</div>
                                ))}
                            </div>
                            <div className="right-chapters">
                                {levels[activeLevel].content.slice(Math.ceil(levels[activeLevel].content.length / 2)).map((chapter, index) => (
                                    <div className="chapter" key={index}>{chapter}</div>
                                ))}
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <div className='padding mpad '>
        <h2 className='hcl'>Clients</h2>
        <img className='clients' src={clients} alt="clients" />
      </div>






            
            <section className='padding'>
                <div className="course-includes">
                    <h2>This course includes</h2>
                    <div className="course-items">
                        <div className="course-item">
                            <span className="icon"><img src={includes} alt="icon1" /></span>
                            30+ hours on-demand video
                        </div>
                        <div className="course-item">
                            <span className="icon"><img src={includes14} alt="icon2" /></span>
                            Live sessions available
                        </div>
                        <div className="course-item">
                            <span className="icon"><img src={includes3} alt="icon3" /></span>
                            Tally job portal access
                        </div>
                        <div className="course-item">
                            <span className="icon"><img src={includes2} alt="icon4" /></span>
                            Assignment for practices
                        </div>
                        <div className="course-item">
                            <span className="icon"><img src={includes4} alt="icon5" /></span>
                            One to one classes
                        </div>
                        <div className="course-item">
                            <span className="icon"><img src={includes5} alt="icon6" /></span>
                            Access on mobile and laptop
                        </div>
                        <div className="course-item">
                            <span className="icon"><img src={includes6} alt="icon7" /></span>
                            Full lifetime access
                        </div>
                        <div className="course-item">
                            <span className="icon"><img src={includes7} alt="icon8" /></span>
                            Certification of completion from Tally Education
                        </div>
                        <div className="course-item">
                            <span className="icon"><img src={includes8} alt="icon9" /></span>
                            Weekly tests
                        </div>
                        <div className="course-item">
                            <span className="icon"><img src={includes9} alt="icon10" /></span>
                            Time flexibility
                        </div>
                    </div>
                </div>
            </section>
            {/* <div className='padding'>
        <img style={{ width: '65%', marginTop: '5%' }} src={exercises} alt="clients" />
      </div> */}
            <section className='padding mpad'>
                <div className="featured-reviews">
                    <h2>Featured reviews</h2>
                    <div className="reviews">
                        <div className="review">
                            <div className="profile">
                                <img src={profile} alt="Profile" className="profile-img" />
                                <div>
                                    <p className="name">Rajat</p>
                                    <p className="stars">
                                        <img src={tara} alt="Star" className="star-img" />
                                        <img src={tara} alt="Star" className="star-img" />
                                        <img src={tara} alt="Star" className="star-img" />
                                        <img src={tara} alt="Star" className="star-img" />
                                        <img src={tara} alt="Star" className="star-img" /></p>

                                </div>
                            </div>
                            <div>
                            </div>
                            <p className="review-text">
                                I completed the Tally Essentials course from Dovetail Solutions, and it was an outstanding experience. The comprehensive curriculum and expert instructors provided me with in-depth knowledge of Tally. This training helped me secure a position at Shivalik Bimetal Controls Pvt. Ltd. as an Executive in Production Planning. I highly recommend this course to anyone looking to advance their career.
                            </p>
                        </div>
                        <div className="review">
                            <div className="profile">
                                <img src={profile1} alt="Profile" className="profile-img" />
                                <div>
                                    <p className="name">Vanshika</p>
                                    <p className="stars">
                                        <img src={tara} alt="Star" className="star-img" />
                                        <img src={tara} alt="Star" className="star-img" />
                                        <img src={tara} alt="Star" className="star-img" />
                                        <img src={tara} alt="Star" className="star-img" />
                                        <img src={tara} alt="Star" className="star-img" />
                                    </p>

                                </div>
                            </div>

                            <p className="review-text">
                                The Tally course at Dovetail solutions  was an outstanding experience. The detailed curriculum and hands-on training equipped me with the necessary skills to excel in my career. After completing the course, I secured a position at Paras Handloom as a Purchase Accountant. I highly recommend this course to anyone looking to advance their accounting knowledge and career prospects.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section class="padding mpad">
                <div className='projects'>
                    <h2>Students also bought</h2>

                    <div class="item-row" onClick={handleRowClick} style={{ cursor: 'pointer' }}>
                        <img src={sqr} alt="profile" class="profile-img" />
                        <div class="item-content">
                            <div class="item-info">
                                <h4>Tally Professional</h4>
                                <p> <span style={{ color: '#0056b3' }}>Total hours 106 • </span> Updated on 6/27/2024</p>
                            </div>
                            <div class="item-stats">
                                <div class="rating">
                                    <img src={tara} alt="star" class="star-img" />
                                    <img src={tara} alt="star" class="star-img" />
                                    <img src={tara} alt="star" class="star-img" />
                                    <img src={tara} alt="star" class="star-img" />
                                    <img src={hstar} alt="star" class="star-img" />
                                    <span>4.7</span>
                                </div>
                                <div class="people">
                                    <img src={people} alt="people" class="people-img" />
                                    <span>48,392</span>
                                </div>
                            </div>
                        </div>
                    </div>



                    {/* <div class="item-row" onClick={handleRowClick1} style={{ cursor: 'pointer' }}>
                        <img src={sqr1} alt="profile" class="profile-img" />
                        <div class="item-content">
                            <div class="item-info">
                                <h4>Tally Prime GST e-Filing Course</h4>
                                <p> <span style={{ color: '#0056b3' }}>Total hours 20 • </span> Updated on 6/27/2024</p>
                            </div>
                            <div class="item-stats">
                                <div class="rating">
                                    <img src={tara} alt="star" class="star-img" />
                                    <img src={tara} alt="star" class="star-img" />
                                    <img src={tara} alt="star" class="star-img" />
                                    <img src={tara} alt="star" class="star-img" />
                                    <img src={hstar} alt="star" class="star-img" />
                                    <span>4.9</span>
                                </div>
                                <div class="people">
                                    <img src={people} alt="people" class="people-img" />
                                    <span>48,567</span>
                                </div>
                                <div class="price">

                                    <span className='rpay'></span>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* <div class="item-row" onClick={handleRowClick2} style={{ cursor: 'pointer' }}>
                        <img src={sqr2} alt="profile" class="profile-img" />
                        <div class="item-content">
                            <div class="item-info">
                                <h4>Financial Accounting Using Tally Prime</h4>
                                <p> <span style={{ color: '#0056b3' }}>Total hours 40 • </span> Updated on 6/27/2024</p>
                            </div>
                            <div class="item-stats">
                                <div class="rating">
                                    <img src={tara} alt="star" class="star-img" />
                                    <img src={tara} alt="star" class="star-img" />
                                    <img src={tara} alt="star" class="star-img" />
                                    <img src={tara} alt="star" class="star-img" />
                                    <img src={hstar} alt="star" class="star-img" />
                                    <span>4.7</span>
                                </div>
                                <div class="people">
                                    <img src={people} alt="people" class="people-img" />
                                    <span>48,492</span>
                                </div>
                                <div class="price">

                                    <span className='rpay'></span>
                                </div>
                            </div>
                        </div>
                    </div> */}


                    <div class="item-row" onClick={handleRowClick3} style={{ cursor: 'pointer' }}>
                        <img src={sq3r} alt="profile" class="profile-img" />
                        <div class="item-content">
                            <div class="item-info">
                                <h4>GST Using Tally Prime</h4>
                                <p> <span style={{ color: '#0056b3' }}>Total hours 90 • </span> Updated on 6/27/2024</p>
                            </div>
                            <div class="item-stats">
                                <div class="rating">
                                    <img src={tara} alt="star" class="star-img" />
                                    <img src={tara} alt="star" class="star-img" />
                                    <img src={tara} alt="star" class="star-img" />
                                    <img src={tara} alt="star" class="star-img" />
                                    <img src={hstar} alt="star" class="star-img" />
                                    <span>4.8</span>
                                </div>
                                <div class="people">
                                    <img src={people} alt="people" class="people-img" />
                                    <span>48,974</span>
                                </div>
                                <div class="price">

                                    <span className='rpay'></span>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </section>
            <section className='footer'>
                <div style={{ textAlign: 'center', color: 'white', paddingTop: '1%', paddingBottom: '1%', fontSize: '10px' }}>
                    All Rights Reserved   |   Powered by Dovetail Solutions
                </div>
            </section>
        </>
    );
}

export default Home;
