import React, { useState, useEffect } from 'react';
import logo from './assets/Frame 82.svg';
import './App.css';
import { useNavigate } from 'react-router-dom';
import Enrollment1form from './enrollment1';
import img1 from './assets/webprofes.svg';
import mobile from './assets/mprofes.svg'; // Import the mobile image
import video from './assets/video.png';
import books from './assets/Frame 96 (1).svg';
import gif from './assets/fast-forward.gif'
import alarm from './assets/alarm.svg';
import arrow from './assets/Asset 1 (1).svg';
import clients from './assets/Frame 88.svg';

import includes from './assets/includes/1.svg';
import includes2 from './assets/includes/10.svg';
import includes3 from './assets/includes/2.svg';
import includes4 from './assets/includes/3.svg';
import includes14 from './assets/includes/4.svg';
import includes5 from './assets/includes/5.svg';
import includes6 from './assets/includes/6.svg';
import includes7 from './assets/includes/7.svg';
import includes8 from './assets/includes/8.svg';
import includes9 from './assets/includes/9.svg';
import profile from './assets/review/Ellipse 7.svg';
import profile1 from './assets/review/Ellipse 6.svg';
import star from './assets/5str.png';
import students from './assets/Frame 66.svg';
import sqr from './assets/Rectangle 13.svg';
import sqr1 from './assets/Rectangle 13 (1).svg';
import sqr2 from './assets/Rectangle 13 (2).svg';
import sq3r from './assets/Rectangle 13 (3).svg';
import sqr4 from './assets/Rectangle 13 (4).svg';
import people from './assets/people.png';
import tara from './assets/tara.png';
import rupee from './assets/rupay.png';
import cross from './assets/cancel_24dp_FILL0_wght400_GRAD0_opsz24 1.svg';
import hstar from './assets/hstar.svg';

const levels = [
  {
    title: 'VOLUME 1- (RECORDING & REPORTING)',
    content: [
      
      'Chapter 1: Multi Currency',
      'Chapter 2: Interest Calculation',
      'Chapter 3: Manufacturing Process-Job Work',
      'Chapter 4: Job costing',
      'Chapter 5: Advanced Banking Transactions ',
      'Chapter 6: Connectivity Features in TallyPrime using Tally.NET',
     
    ],
  },
  {
    title: 'VOLUME 2 - ACCOUNTS EXECUTIVE ',
    content: [
      'Chapter 1:  Recording Advanced GST Transactions  ',
      'Chapter 2:  Recording Advanced TDS Transactions: ',
      'Chapter 3: Tax Collected at Source (TCS) ',
      'Chapter 4: Payroll and Income Tax',
      
    ],
  },
 
];

const handleRemove = () => {
  document.getElementById('headerBar').style.display = 'none';
};

function TProfessional() {
  const [activeLevel, setActiveLevel] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [imageSrc, setImageSrc] = useState(img1); // State to manage the image source
  const navigate = useNavigate();

  const handleRowClick = () => {
    navigate('/');
  };
  const handleRowClick1 = () => {
    navigate('/tprime');
  };
  const handleRowClick2 = () => {
    navigate('/financial');
  };
  const handleRowClick3 = () => {
    navigate('/gst');
  };

  const handleEnroll1Click = () => {
    setShowModal(true);
};

const handleCloseModal = () => {
    setShowModal(false);
};

useEffect(() => {
  const handleResize = () => {
      if (window.innerWidth <= 768) {
          setImageSrc(mobile); // Change the image source to mobile
      } else {
          setImageSrc(img1); // Reset the image source to img1
      }
  };

  window.addEventListener('resize', handleResize);

  // Check the screen size initially
  handleResize();

  return () => {
      window.removeEventListener('resize', handleResize);
  };
}, []);


  useEffect(() => {
    const handleScroll = () => {
      const card = document.querySelector('.card');
      if (window.scrollY > 0) {
        if (window.innerWidth <= 768) { // Assuming mobile screens are 768px or less
          card.style.marginTop = '0%';
        } else {
          card.style.marginTop = '-42%';
        }
      } else {
        if (window.innerWidth <= 768) { // Assuming mobile screens are 768px or less
          card.style.marginTop = '0%';
        } else {
          card.style.marginTop = '-27%';
        }
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  
  const initialCountdownTime = 3 * 60 * 60 + 55 * 60 + 30; // initial countdown time in seconds
  const [countdownTime, setCountdownTime] = useState(initialCountdownTime);

  useEffect(() => {
      if (countdownTime > 0) {
          const timerId = setTimeout(() => {
              setCountdownTime(countdownTime - 1);
          }, 1000);

          return () => clearTimeout(timerId); // Cleanup the timeout on component unmount
      }
  }, [countdownTime]);

  const formatTime = (time) => {
      const hours = Math.floor(time / 3600);
      const minutes = Math.floor((time % 3600) / 60);
      const seconds = time % 60;

      return `${hours}h ${minutes}m ${seconds}s`;
  };


  return (
    <>
    <section className='contain'>
      <div className='tcard'>

      <div className="header-bar" id="headerBar">
          <img src={cross} alt="cross" className="cross-img" onClick={handleRemove} />
          <div className="header-content">
            <p>"Last Chance! Limited Time Offer on Our Popular Course!"</p>
            <p style={{ fontWeight: 'bold', textAlign: 'center' }}>1 day left</p>
          </div>
        </div>

        <navbar className="navbar padding">
                    <img src={logo} alt="Logo" className="navbar-logo" />
                    <div className="image-container" onClick={handleEnroll1Click} style={{ cursor: 'pointer', marginLeft: '20px', position: 'relative' }}>
                                <img  src={books} alt="Books" className='books' />
                                <img src={gif} alt="Overlay" className="overlay-gif" />
                    </div>

     
                </navbar>
        <div className='wide'>
                    <img id="responsive-img" src={imageSrc} alt="img1" />
                </div>
        <div className="card">
        <div className="card-video">
        <iframe
  className='youtube'
  src="https://www.youtube.com/embed/nwH94e2Vqvo?si=v2iVkoFxtkc0DpXn"
  title="YouTube video player"
  frameBorder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
  referrerPolicy="strict-origin-when-cross-origin"
  allowFullScreen
></iframe>
    </div>
          <div className="card-content">
          <span> <h3 style={{ marginBottom: '0px', color: 'red',fontWeight:'570' }}>50% OFF</h3> </span>
                        <p style={{ marginTop: '0px', paddingTop: '1px' }}>Total duration: 106 hrs</p>
                        <div className="limited-time-offer">
                            <span className="icon"><img src={alarm} alt="alarm" /></span>
                            <span className='offer' style={{color:'red'}}> <strong>Limited time offer :<span id="countdown">{formatTime(countdownTime)}</span></strong></span>

                        </div>
            <button className="enroll-button" onClick={handleEnroll1Click}><span>Enroll now </span></button>
            <div className="card-includes">
              <h4 style={{ marginBottom: '1px' }}>This course includes</h4>

              <div className="course-item">
                <span className="icon"><img src={includes} alt="icon1" /></span>
                30+ hours on-demand video
              </div>
              <div className="course-item">
                <span className="icon"><img src={includes14} alt="icon2" /></span>
                Live sessions available
              </div>
              <div className="course-item">
                <span className="icon"><img src={includes3} alt="icon3" /></span>
                Tally job portal access
              </div>
              <div className="course-item">
                <span className="icon"><img src={includes2} alt="icon4" /></span>
                Assignment for practices
              </div>
            </div>

          </div>
        </div>
      </div>
      <Enrollment1form show={showModal} handleClose={handleCloseModal} />
      <section className='second-section'>
       

      </section>
      </section>
      <section className='padding'>
  <div className='learn'>
    <h2>What you will learn</h2>
    <div className="learn-item">
      <span className="arrow"><img src={arrow} alt="arrow" /></span>
      Creation and alteration of foreign currencies
    </div>
    <div className="learn-item">
      <span className="arrow"><img src={arrow} alt="arrow" /></span>
      Introduction to interest calculation
    </div>
    <div className="learn-item">
      <span className="arrow"><img src={arrow} alt="arrow" /></span>
      Accounting of simple interest and compound interest
    </div>
    <div className="learn-item">
      <span className="arrow"><img src={arrow} alt="arrow" /></span>
      Accounting of interest on outstanding balances
    </div>
    <div className="learn-item">
      <span className="arrow"><img src={arrow} alt="arrow" /></span>
      Generating the interest calculation reports
    </div>
    <div className="learn-item">
      <span className="arrow"><img src={arrow} alt="arrow" /></span>
      Introduction to Job Order Processing
    </div>
    <div className="learn-item">
      <span className="arrow"><img src={arrow} alt="arrow" /></span>
      Accounting of Job Work Out Order processing
    </div>
    <div className="learn-item">
      <span className="arrow"><img src={arrow} alt="arrow" /></span>
      Introduction to job costing
    </div>
    <div className="learn-item">
      <span className="arrow"><img src={arrow} alt="arrow" /></span>
      Features of job costing
    </div>
    <div className="learn-item">
      <span className="arrow"><img src={arrow} alt="arrow" /></span>
      Recording job costing related transactions in Tally
    </div>
    <div className="learn-item">
      <span className="arrow"><img src={arrow} alt="arrow" /></span>
      Generating job costing reports
    </div>
    <div className="learn-item">
      <span className="arrow"><img src={arrow} alt="arrow" /></span>
      Introduction to advanced banking transactions
    </div>
    <div className="learn-item">
      <span className="arrow"><img src={arrow} alt="arrow" /></span>
      Post-dated cheque management
    </div>
    <div className="learn-item">
      <span className="arrow"><img src={arrow} alt="arrow" /></span>
      Introduction to E-Way Bill, GST e-Invoicing
    </div>
    <div className="learn-item">
      <span className="arrow"><img src={arrow} alt="arrow" /></span>
      Purchases from Unregistered Dealer
    </div>
    <div className="learn-item">
      <span className="arrow"><img src={arrow} alt="arrow" /></span>
      Purchases from Composition Dealer
    </div>
    <div className="learn-item">
      <span className="arrow"><img src={arrow} alt="arrow" /></span>
      Accounting Mixed Supply and Composite Supply
    </div>
  
  </div>
</section>

      <section className='padding mpad'>
        <div className="title">Tally Professional</div>
        <div className="duration">Total duration: 106 hrs</div>
        <div className="container">
          <div className="level-tabs-container">
            <div className="level-tabs">
              <div className="bottom-lines"></div>

              <div
                className={`level-tab ${activeLevel === 0 ? 'active' : ''}`}
                onClick={() => setActiveLevel(0)}
              >
                Vol . 1
              </div>
              <div
                className={`level-tab ${activeLevel === 1 ? 'active' : ''}`}
                onClick={() => setActiveLevel(1)}
              >
                Vol . 2
              </div>
              
            </div>


          </div>


          <div className="content">
            <div className="level-title">{levels[activeLevel].title}</div>
            <div className="chapter-section">
              <div className="left-chapters">
                {levels[activeLevel].content.slice(0, Math.ceil(levels[activeLevel].content.length / 2)).map((chapter, index) => (
                  <div className="chapter" key={index}>{chapter}</div>
                ))}
              </div>
              <div className="right-chapters">
                {levels[activeLevel].content.slice(Math.ceil(levels[activeLevel].content.length / 2)).map((chapter, index) => (
                  <div className="chapter" key={index}>{chapter}</div>
                ))}
              </div>
            </div>
          
          </div>
        </div>
      </section>
      <div className='padding mpad '>
        <h2 className='hcl'>Clients</h2>
        <img className='clients' src={clients} alt="clients" />
      </div>
      <section className='padding'>
  <div className="course-includes">
    <h2>This course includes</h2>
    <div className="course-items">
      <div className="course-item">
        <span className="icon"><img src={includes} alt="icon1" /></span>
        100+ hours on-demand video
      </div>
      <div className="course-item">
        <span className="icon"><img src={includes5} alt="icon6" /></span>
        Access on mobile and laptop
      </div>
      <div className="course-item">
        <span className="icon"><img src={includes6} alt="icon7" /></span>
        Full lifetime access
      </div>
      <div className="course-item">
        <span className="icon"><img src={includes7} alt="icon8" /></span>
        Certificate of completion from Tally Education
      </div>
      <div className="course-item">
        <span className="icon"><img src={includes14} alt="icon10" /></span>
        Internet National Certificate Value
      </div>
      <div className="course-item">
        <span className="icon"><img src={includes3} alt="icon3" /></span>
        Tally Job Portal Access
      </div>
      <div className="course-item">
        <span className="icon"><img src={includes2} alt="icon4" /></span>
        Assignment for Practices
      </div>
      <div className="course-item">
        <span className="icon"><img src={includes8} alt="icon9" /></span>
        Weekly Tests
      </div>
      <div className="course-item">
        <span className="icon"><img src={includes4} alt="icon5" /></span>
        One to one classes
      </div>
      <div className="course-item">
        <span className="icon"><img src={includes9} alt="icon10" /></span>
        Batch According to You
      </div>
    </div>
  </div>
</section>

      {/* <div className='padding'>
        <img style={{ width: '65%', marginTop: '5%' }} src={exercises} alt="clients" />
      </div> */}
      <section className='padding mpad'>
        <div className="featured-reviews">
          <h2>Featured reviews</h2>
          <div className="reviews">
            <div className="review">
              <div className="profile">
                <img src={profile} alt="Profile" className="profile-img" />
                <div>
                  <p className="name">  Pallavi</p>
                  < p className="stars">
                                <img src={tara} alt="Star" className="star-img" />
                                <img src={tara} alt="Star" className="star-img" />
                                <img src={tara} alt="Star" className="star-img" />
                                <img src={tara} alt="Star" className="star-img" />
                                <img src={tara} alt="Star" className="star-img" /></p>
                 
                </div>
              </div>
          
             
              
              <p className="review-text">
            
              I had a fantastic experience with the Tally Professional course at  Dovetail solutions The training was thorough and covered all essential aspects of Tally. I am now employed as a Tally Trainer at Dovetail Solutions, and I owe my success to the excellent education I received here.
              </p>
            </div>
            <div className="review">
              <div className="profile">
                <img src={profile1} alt="Profile" className="profile-img" />
                <div>
                  <p className="name">Sushil</p>
                  <p className="stars">
                                <img src={tara} alt="Star" className="star-img" />
                                <img src={tara} alt="Star" className="star-img" />
                                <img src={tara} alt="Star" className="star-img" />
                                <img src={tara} alt="Star" className="star-img" />
                                <img src={tara} alt="Star" className="star-img" /></p>
                 
                </div>
              </div>
             
              
              <p className="review-text">
              The Tally course at Dovetail Solutions was incredibly beneficial. The practical approach and supportive faculty made the learning process enjoyable. Thanks to this course, I am now working with the Electricity Board in Solan, Himachal Pradesh. Dovetail Solutions is the perfect place to master Tally.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section class="padding mpad">
        <div className='projects'>
          <h2>Students also bought</h2>

          <div class="item-row" onClick={handleRowClick} style={{ cursor: 'pointer' }}>
            <img src={sqr1} alt="profile" class="profile-img" />
            <div class="item-content">
            <div class="item-info">
              <h4>Tally Essentials</h4>
              <p> <span style={{ color: '#0056b3' }}>Total hours 106 • </span>Updated on 6/27/2024</p>
            </div>
            <div class="item-stats">
              <div class="rating">
              <img src={tara} alt="star" class="star-img" />
                                <img src={tara} alt="star" class="star-img" />
                                <img src={tara} alt="star" class="star-img" />
                                <img src={tara} alt="star" class="star-img" />
                                <img src={hstar} alt="star" class="star-img" />
                                <span>4.8</span>
                
              </div>
               <div class="people">
                <img src={people} alt="people" class="people-img" />
                <span>50,492</span>
              </div> 
              <div class="price">

              
              </div>
              </div>
            </div>
          </div>

          {/* <div class="item-row"onClick={handleRowClick1} style={{ cursor: 'pointer' }}>
            <img src={sqr2} alt="profile" class="profile-img" />
            <div class="item-content">
            <div class="item-info">
              <h4>Tally Prime GST e-Filing Course</h4>
              <p> <span style={{ color: '#0056b3' }}>Total hours 20 • </span>Updated on 6/27/2024</p>
            </div>
            <div class="item-stats">
              <div class="rating">
              <img src={tara} alt="star" class="star-img" />
                                <img src={tara} alt="star" class="star-img" />
                                <img src={tara} alt="star" class="star-img" />
                                <img src={tara} alt="star" class="star-img" />
                                <img src={hstar} alt="star" class="star-img" />
                                <span>4.9</span>
              </div>
               <div class="people">
                <img src={people} alt="people" class="people-img" />
                <span>48,567</span>
              </div> 
              <div class="price">

              </div>
            </div>
            </div>
          </div> */}

          {/* <div class="item-row"onClick={handleRowClick2} style={{ cursor: 'pointer' }}>
            <img src={sq3r} alt="profile" class="profile-img" />
            <div class="item-content">
            <div class="item-info">
              <h4>Financial Accounting Using Tally Prime</h4>
              <p> <span style={{ color: '#0056b3' }}>Total hours 40 • </span>Updated on 6/27/2024</p>
            </div>
            <div class="item-stats">
              <div class="rating">
              <img src={tara} alt="star" class="star-img" />
                                <img src={tara} alt="star" class="star-img" />
                                <img src={tara} alt="star" class="star-img" />
                                <img src={tara} alt="star" class="star-img" />
                                <img src={hstar} alt="star" class="star-img" />
                                <span>4.7</span>
              </div>
               <div class="people">
                <img src={people} alt="people" class="people-img" />
                <span>48,492</span>
              </div> 
              <div class="price">

             
              </div>
            </div>
            </div>
          </div> */}


          <div class="item-row"onClick={handleRowClick3} style={{ cursor: 'pointer' }}>
            <img src={sqr4} alt="profile" class="profile-img" />
            <div class="item-content">
            <div class="item-info">
              <h4>GST Using Tally Prime</h4>
              <p> <span style={{ color: '#0056b3' }}>Total hours 90 • </span>Updated on 6/27/2024</p>
            </div>
            <div class="item-stats">
              <div class="rating">
              <img src={tara} alt="star" class="star-img" />
                                <img src={tara} alt="star" class="star-img" />
                                <img src={tara} alt="star" class="star-img" />
                                <img src={tara} alt="star" class="star-img" />
                                
                                <img src={hstar} alt="star" class="star-img" />
                                <span>4.8</span>
              </div>
               <div class="people">
                <img src={people} alt="people" class="people-img" />
                <span>48,974</span>
              </div> 
              <div class="price">

             
              </div>
            </div>
            </div>
          </div>


        </div>
      </section>
      <section className='footer'>
        <div style={{ textAlign: 'center', color: 'white', paddingTop: '1%', paddingBottom: '1%', fontSize: '10px' }}>
          All Rights Reserved   |   Powered by Dovetail Solutions
        </div>
      </section>
    </>
  );
}

export default TProfessional;
